.table-wrapper {box-shadow: none}

.main-content a {text-decoration: underline;}
.main-content a:hover {color: gray;}

.button-container {
    text-align: center;
  }
  
  .download-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #000;
    text-decoration: none;
    border: 2px solid #000;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .download-button:hover {
    background-color: #fff;
    color: #000;
  }

  .spacer{
    width: 100%;
    text-align:center;
  }