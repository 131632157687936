$color-scheme: dark;
$body-background-color: white;
$body-heading-color: black;
$body-text-color: black;
$link-color: black;
$nav-child-link-color: black;
$sidebar-color: $white;
$base-button-color: black;
$btn-primary-color: black;
$code-background-color: white; // OneDarkJekyll default for syntax-one-dark-vivid
$code-linenumber-color: black; // OneDarkJekyll .nf for syntax-one-dark-vivid
$feedback-color: darken($sidebar-color, 3%);
$table-background-color: white;
$search-background-color: white;
$search-result-preview-color: white;
$border-color: white;

@import "./color_schemes/light";

// @import "./vendor/OneDarkJekyll/syntax"; // this is the one-dark-vivid atom syntax theme

#pwyc_button {
    display:inline-block; 
    padding:10px 20px; 
    font-size:16px; 
    color:#fff; 
    background-color:#000; 
    border:none; 
    border-radius:5px; 
    text-decoration:none; 
    transition: all 0.3s ease;
}

#pwyc_button:hover:after {
    content:"🙏";
}
